import { Button, Dialog } from "element-ui";

const coms = [Button, Dialog];

export default {
  install (Vue, options) {
    coms.map((c) => {
      Vue.component(c.name, c);
    });
  },
};
