import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import element from "../src/plugins/element";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import initKeycloak from "./util/keycloak";

Vue.use(ElementUI);
Vue.use(element);
Vue.config.productionTip = false;

let keycloakInstance;

initKeycloak().then((keycloak) => {
  keycloakInstance = keycloak;
  Vue.prototype.$keycloak = keycloakInstance.keycloak;

  new Vue({
    el: "#app",
    router,
    render: (h) => h(App),
    mounted() {
      this.$router.push("/");
    },
  });
  // 当用户关闭浏览器或者浏览器标签页时，停止刷新token的定时器
  window.addEventListener("beforeunload", function (event) {
    keycloakInstance.stopRefreshToken();
  });
});
