import Keycloak from "keycloak-js";
import Vue from "vue";

const REFRESH_INTERVAL = 6000; // Token刷新间隔
const REFRESH_ADVANCE = 70; // 提前刷新时间

const AUTH_URL = "https://login.guiyunbao.cn";
const REALM = "guiyunbao";
const CLIENT_ID = "account";
const ON_LOAD = "login-required";

let initOptions = {
  url: AUTH_URL,
  realm: REALM,
  clientId: CLIENT_ID,
  onLoad: ON_LOAD,
};

async function initKeycloak() {
  let keycloak = new Keycloak(initOptions);
  let refreshIntervalId;

  try {
    let auth = await keycloak.init({ onLoad: initOptions.onLoad });

    if (!auth) {
      window.location.reload();
    } else {
      console.log("Authenticated");
      Vue.prototype.$keycloak = keycloak;
    }

    // Token Refresh
    refreshIntervalId = setInterval(async () => {
      try {
        await keycloak.updateToken(REFRESH_ADVANCE);
        Vue.prototype.$keycloak = keycloak;
      } catch {
        console.error("Failed to refresh token");
      }
    }, REFRESH_INTERVAL);

    return {
      keycloak,
      stopRefreshToken: () => clearInterval(refreshIntervalId),
    };
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export default initKeycloak;
