<template>
  <div id="app">
    <el-button class="logout-button" type="primary" @click="logout">退出</el-button>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {};
  },

  methods: {
    logout() {
      this.$keycloak.logout();
    },
  },
};
</script>

<style>
#app {
  position: relative;
  height: 100vh;
}

.logout-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
